<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : "Add"} hostel type` }}
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="resetForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="hostelType.title"
                                outlined
                                dense
                                :error="$v.hostelType.title.$error"
                            >
                                <template v-slot:label>
                                    Title <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.hostelType.title.$error">This information is required</span>
                        </v-col>
                        

                        <v-col cols="12">
                            <label>
                                Status
                            </label>
                            <v-switch v-model="hostelType.is_active" :label="hostelType.is_active ? 'Active' : 'Inactive'"></v-switch>
                            <span class="text-danger" v-if="$v.hostelType.is_active.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn 
                                    depressed 
                                    @click="resetForm" 
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>
                                <!-- <v-btn 
                                    v-if="checkIsAccessible('gate-pass', 'create')" 
                                    depressed 
                                    :loading="isBusy"
                                    @click="createOrUpdate" 
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn> -->
                                <v-btn 
                                    depressed 
                                    :loading="isBusy"
                                    @click="createOrUpdate" 
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import HostelTypeService from "@/core/services/hostel/hostel-type/HostelTypeService";

const hostelType = new HostelTypeService();

export default {
    validations: {
        hostelType: {
            title: { required },
            is_active: {}
        }
    },
    data() {
        return {
            dialog: false,
            edit: false,
            isBusy: false,
            hostelType: {
                title: '',
                is_active: true
            },
        };
    },
    methods: {
        showModal(id = null) {
            if (id) {
                this.edit = true
                this.getHostelType(id)
            }
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        },
        getHostelType(id) {
            hostelType
            .show(id)
            .then(response => {
                this.hostelType = response.data.hostelType;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
        createOrUpdate() {
            this.$v.hostelType.$touch();
            if (this.$v.hostelType.$error) {
                setTimeout(() => {
                    this.$v.hostelType.$reset();
                }, 3000);
            } else {
                if (this.edit) {
                    this.updateHostelType();
                } else {
                    this.createHostelType(this.hostelType);
                }
            }
        },
        updateHostelType() {
            this.isBusy = true;
            hostelType
            .update(this.hostelType.id, this.hostelType)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.resetForm();
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        createHostelType(fd) {
            this.isBusy = true;
            hostelType
            .store(this.hostelType)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.$snotify.success("Information added");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.hostelType.$reset();
            this.edit = false;
            this.hostelType = {
                title: '',
                is_active: true
            };
            this.hideModal();
            this.$emit("refresh");
        }
    }
}
</script>
