<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4> Manage hostel type</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ Hostel type
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn class="mt-4 btn btn-primary" style="color: #fff" @click="addHostelType">
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Add hostel type
                                    </v-btn>&nbsp;&nbsp;

                                    <!-- <v-btn 
                                        v-if="checkIsAccessible('gate-pass', 'create')" 
                                        @click="addHostelType" 
                                        class="mt-4 btn btn-primary" 
                                        style="color: #fff"
                                    >
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Add gate pass
                                    </v-btn>&nbsp;&nbsp; -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" sm="6" md="12">
                                    <v-text-field class="form-control" v-model="search.title"
                                        label="Search by title" outlined clearable
                                        v-on:keyup.enter="getHostelTypes()" dense>
                                    </v-text-field>
                                </v-col>
                                
                                <v-col cols="12" md="12">
                                    <v-btn :loading="loading" @click.prevent="searchHostelTypes()"
                                        class="btn btn-primary w-35 float-right" dark>
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>
                        <div class="">
                            <v-skeleton-loader v-if="loading" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table" v-if="!loading">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3">Title</th>
                                        <th class="px-3">Status</th>
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="hostelTypes.length > 0" v-for="(item, index) of hostelTypes" :key="item.id">
                                        <td class="px-3">
                                            {{ item.title }}
                                        </td>
                                        <td class="px-3">
                                            <span 
                                                class="badge badge-success text-lg "
                                                v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }">
                                                {{ item.is_active ? 'active' : 'Inactive' }}
                                            </span>
                                        </td>
                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="editHostelType(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="deleteHostelType(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-delete"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="hostelTypes.length == 0">
                                        <td class="text-center  px-3" colspan="7">Data not available</td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination 
                                v-show="hostelTypes.length > 0" 
                                @input="getHostelTypes" 
                                class="pull-right mt-7" 
                                v-model="page"
                                :total-rows="total" 
                                :per-page="perPage" 
                                first-number 
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <create-and-update ref="hostel-type" @refresh="getHostelTypes"></create-and-update>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import HostelTypeService from "@/core/services/hostel/hostel-type/HostelTypeService";
import CreateAndUpdate from "./CreateAndUpdate"

const hostelType = new HostelTypeService();

export default {
    components: {
        CreateAndUpdate
    },
    data() {
        return {
            search: {
                title: '',
            },
            loading: false,
            hostelTypes: [],
            page: null,
            perPage: null,
            total: null,
        }
    },
    methods: {
        searchHostelTypes(){
            this.page = 1;
            this.getHostelTypes();
        },  
        getHostelTypes() {
            this.loading = true;
            hostelType
                .paginate(this.search, this.page)
                .then(response => {
                    this.hostelTypes = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                });
        },
        addHostelType() {
            this.$refs["hostel-type"].showModal();
        },
        editHostelType(id) {
            this.$refs["hostel-type"].showModal(id);
        },
        deleteHostelType(hostelTypeId){
            this.$confirm({
                message: `Are you sure you want to delete?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        hostelType
                        .delete(hostelTypeId)
                        .then(response => {
                            this.$snotify.success('Successfully deleted')
                            this.getHostelTypes();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        }
    },
    mounted() {
        this.getHostelTypes();
    }
}
</script>